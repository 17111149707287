import { ArrowRight } from "lucide-react";
import Link from "@/components/ui/Link";
import { sanitizeHTML } from "@/lib/utils";
import ExpandingPanel from "./ExpandingPanel";
import Button from "../ui/button";

interface QuestionsData {
  heading?: {
    text?: string;
    title?: string;
  };
  content_collection?: {
    id: string;
    site_content_collections_id: {
      collection: {
        item: {
          title: string;
          text: string;
        };
      }[];
    };
  }[];
  buttons?: {
    menu_items_id: {
      href: string;
      label: string;
    };
  }[];
  button?: {
    label?: string;
    href?: string;
  };
  subheading?: {
    text?: string;
    title?: string;
  };
  video?: {
    embed_url: string;
    embed_title: string;
  };
  text?: {
    text?: string;
  };
}

export const fields = [
  "heading.text",
  "heading.title",
  "subheading.text", 
  "subheading.title",
  "content_collection.id",
  "content_collection.site_content_collections_id.collection.item:site_content.title",
  "content_collection.site_content_collections_id.collection.item:site_content.text",
  "buttons.menu_items_id.href",
  "buttons.menu_items_id.label",
  "text.text",
  "button.label",
  "button.href",
  "video.embed_url",
  "video.embed_title",
] as const;

export default function Questions({ data }: { data?: QuestionsData }) {
  return (
    <section id="questions" className="container max-w-[1183px] py-16">
      {data?.subheading && (
        <h4 
          className="font-freightBig text-sm text-center mt-[10px] capitalize"
          dangerouslySetInnerHTML={sanitizeHTML(data.subheading.title || data.subheading.text || '')}
        />
      )}
      <h2 
        className="text-3xl lg:text-4xl leading-[3rem] font-freightBig mb-10 lg:mb-16"
        dangerouslySetInnerHTML={sanitizeHTML(data?.heading?.title || data?.heading?.text || 'Questions About London Facial Plastic Surgery')}
      />
      {data?.text?.text && (
        <div className="mt-[20px] text-center">
          <p dangerouslySetInnerHTML={sanitizeHTML(data.text.text)} />
        </div>
      )}
      {data?.video && (
        <div className="mt-[20px] flex justify-center">
          <iframe
            width="560"
            height="315"
            src={data.video.embed_url}
            title={data.video.embed_title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            loading={"lazy"}
          ></iframe>
        </div>
      )}
      <div className="flex flex-col gap-8 prose-li:my-2 prose-li:text-black prose-ul:pl-5 prose-ul:list-disc prose-ul:text-black prose-li:marker:text-black">
        {data?.content_collection?.map((item) => {
          const collection = item.site_content_collections_id?.collection || [];
          const title = collection.find(c => c.item?.title)?.item?.title || '';
          const text = collection.find(c => c.item?.text)?.item?.text || '';
          
          return (
            <ExpandingPanel
              key={item.id}
              title={title}
              className="space-y-[14px]"
            >
              <div dangerouslySetInnerHTML={sanitizeHTML(text)} />
            </ExpandingPanel>
          );
        })}
      </div>
      {data?.button && (
        <div className="flex justify-center">
          <Button asChild variant="outline">
            <Link
              href={data.button.href || '#'}
              className="mt-[50px]"
            >
              {data.button.label}
            </Link>
          </Button>
        </div>
      )}
      {data?.buttons && data.buttons.length > 0 && (
        <div className="flex justify-center gap-4">
          {data.buttons.map((button, index) => (
            <Button key={index} asChild variant="outline">
              <Link
                href={button.menu_items_id.href || '#'}
                className="mt-[50px]"
              >
                {button.menu_items_id.label}
              </Link>
            </Button>
          ))}
        </div>
      )}
    </section>
  );
}
